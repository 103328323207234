img {
  /* IE10 */
  border: 0px;
}

.headerContainer {
  margin-top: 54px;
}

.header {
  transition: top .2s;
  margin: 0 auto;
  position: fixed;
  top: 0;
  width: 100%;
  height: 55px;
  z-index: 10;
  -webkit-font-smoothing: antialiased;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.headerWrapper {
  max-width: 1700px;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 10px;
  margin: auto;
  position: relative;

}

.header a {
  flex-wrap: nowrap;
  display: flex;
  text-decoration: none;
}

.header h1 {
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  float: left;
  margin: 0;
  padding: 0;
}

.header h1 img {
  display: block;
  margin: auto;
  height: 100%;
}

.header h2 {
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin: 0;
  height: 100%;
  padding-left: 10px;
}

.header h2 span {
  vertical-align: top;
  font: normal 12px/15px title, helvetica, sans-serif;
  text-transform: uppercase;
}


.header nav {
  text-align: right;
  justify-content: flex-end;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  height: 100%;
  text-transform: uppercase;
  text-decoration: none;
  font: 16px/28px latoblack, helvetica, sans-serif;
}

.headerLogin {
  border-top-style: solid;
  border-top-width: 0px;
}

.headerLogin>a {
  margin-right: 20px;

}

.headerJoin {
  margin-right: 0px;
  display: block;
  padding: 0 15px;
  border-radius: 2px;
}


@media (max-width: 700px) {
  .headerContainer {
    margin-top: 100px;
  }
  
  .header {
    height: auto;
  }
  .headerWrapper {
    padding: 0;
    height: auto;
    justify-content: center;
    flex-direction: column;

  }
  .headerWrapper>a {
    padding: 10px 0;
    height: 50px;
  }

  .header h2 {
    display: none;
  }

  .header nav {
    flex-wrap: nowrap;
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
  }
  .headerLogin {
    width: 50%;
    margin: 0;
    text-align: center;
    font: 22px/1 latoregular, helvetica, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-width: 1px;

  }
  .headerJoin {
    padding: 3px;
    width: 50%;
    margin: 0;
    border-radius: 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .headerJoin a {
    font: 22px/1 latoregular, helvetica, sans-serif;
    max-width: 200px
  }
}
